:root{
  --min-width: 200px
}

@font-face{
  font-family:Roboto;

  src:url(/static/media/Roboto-Thin.321de678.ttf)
}

.color-primary{
  color:#2b6cb0
}

.color-secondary{
  color:#a23a37
}

.color-discreet{
  color:#93a89e
}

.color-alert{
  color:#892332
}

.color-success{
  color:#d6ab67
}

.text-title{
  font-family:'PostoniWide', 'Bodoni 72', 'Bodoni MT', Didot, 'Didot LT STD', 'Hoefler Text', Garamond, Georgia, serif;
  font-weight:700
}

.form{
  border:1px dotted #892332
}

@media handheld{
  .form{
    border:none
  }
}

.cover{
  display:flex;
  justify-content:center
}

body{
  margin:0;
  background-color:#fff5ee
}

div{
  box-sizing:border-box
}

p{
  overflow-wrap:break-word
}

a{
  color:#444
}

#redwood-app{
  display:flex;
  place-content:center
}

.MuiLink-root.MuiTypography-root{
  cursor:crosshair
}

.MuiTypography-root .MuiTypography-root{
  padding:unset
}

.active{
  border:1px solid red
}
